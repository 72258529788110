import React from 'react';
import '../styles/Page404.scss';
import { Link } from 'gatsby';

interface Props {}

const Page404 = (props: Props) => {
  return (
    <div className='Page404'>
      <div className='Page404__title'>404</div>
      <Link to='/' className='Page404__link'>
        На головну
      </Link>
    </div>
  );
};

export default Page404;
